
.pages:focus{
    border-bottom: 2px solid gray;
    color: yellow;
    
}

.logo{
    cursor: pointer;
    transition: transform .5s;
    margin: 0 auto;
    width: 120px;
    height: 120px;
}
.logo:hover{
    transform: scale(1.6);
}