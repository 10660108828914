@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@600&family=Montserrat&display=swap');

.project-title-heading{
    text-align: center;
    font-size: 30px;
    letter-spacing: 15px;
    padding: 15px;
    color: white;
    text-shadow: 0px 0px rgb(34, 29, 42), 2px 3px 4px rgb(67, 15, 67), 3px 4px 8px lightskyblue;
}
.project_title{
    font-size: 20px;
    letter-spacing: 12px;
    padding: 15px;
    color: white;
    text-shadow: 0px 0px rgb(34, 29, 42), 2px 3px 4px rgb(67, 15, 67), 3px 4px 8px lightskyblue;
}
.project_box{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    padding: 25px;
    margin-bottom: 20px;
    background-image: url('../Images/Gradient\ Bg.webp');
    background-size: cover;
    box-shadow: 0px -1px 20px skyblue;

}
.hide-card{
    display: none;
}
.show-card{
    display: "flex";
}
@media screen and (max-width: 564px){
    .project_box{
        width: 100%;
        margin: 0;
        padding: 15px;
        overflow: hidden;
    }
}
