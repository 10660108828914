.icon{
    font-size: 25px;
    color: gray;
    transition: transform .3s;
    margin: 0;
}
.icon:hover{
    transform: scale(1.5);
    width: 26px;
    height: 26;
    color: white;
    border-radius: 20px;
    box-shadow: 0 0 13px lightblue;
}